<template>
  <div v-if="!isMobile()" class="error--text text-h6 text-center">
    {{ $t("messages.function_work_on_mobile_only") }}
  </div>
  <div v-else>
    <div v-if="!isChecking">
      <div v-if="!isTimekeepingAllowed" class="text-center">
        <p class="error--text text-h6">
          {{ $t("messages.device_not_active") }}
        </p>
        <p>
          {{ $t("labels.active_code") }}
          <span class="primary--text">{{ deviceId.slice(0, 10) }}</span>
        </p>
      </div>
      <div v-else>
        <div>
          <v-row dense>
            <!--<v-col cols="12">
              <v-radio-group v-model="type" row dense hide-details class="mt-0">
                <v-radio :label="$t('labels.checkin')" value="checkin"></v-radio>
                <v-radio :label="$t('labels.checkout')" value="checkout"></v-radio>
              </v-radio-group>
            </v-col>-->

            <template v-if="!coords.latitude || !coords.longitude">
              <v-col cols="12" class="error--text">
                {{ $t("messages.can_not_get_location") }}
              </v-col>
              <!-- <v-col cols="12" class="error--text">
                <v-btn block outlined color="warning" @click="handlePermission">
                  <v-icon>mdi-map-marker</v-icon>
                  Cấp quyền vị trí
                </v-btn>
              </v-col> -->
            </template>

            <v-col cols="12">
              <v-btn
                block
                color="info"
                outlined
                @click="$refs.inputUploadFile.click()"
              >
                {{ $t("labels.take_photo") }}
              </v-btn>
              <input
                type="file"
                ref="inputUploadFile"
                accept="image/*"
                @change="onInputFileChange"
                capture="user"
                class="d-none"
              />
            </v-col>

            <!-- <v-col cols="12">
              <v-checkbox dense hide-details class="c-input-small" v-model="isSequential" :value="true"
                label="Làm tiếp ca sau"></v-checkbox>
            </v-col> -->

            <v-col cols="12">
              <v-btn
                block
                color="success"
                :disabled="!fileUrl || !coords.latitude || !coords.longitude"
                @click="onCheck"
              >
                {{ $t("labels.timekeeping") }}
              </v-btn>
            </v-col>
          </v-row>
        </div>

        <v-fab-transition>
          <v-btn
            dark
            fixed
            bottom
            right
            fab
            color="info"
            @click="showDialogList"
          >
            <v-icon>mdi-information-outline</v-icon>
          </v-btn>
        </v-fab-transition>

        <v-col cols="12" v-if="fileUrl">
          <div>
            <img :src="fileUrl" alt="" style="width: 100%; height: auto" />
          </div>
        </v-col>
      </div>
    </div>
    <div v-else class="text-center">Loading...</div>

    <v-dialog v-model="dialogList" persistent max-width="960px">
      <TimekeepingEmployee v-if="dialogList" @cancel="hideDialogList" />
    </v-dialog>

    <v-dialog persistent v-model="sequentialDialog" max-width="360px">
      <v-card>
        <v-card-title class="text-h6">Làm tiếp ca sau</v-card-title>
        <v-card-text
          >Có ca nối tiếp, bạn có muốn chấm công ca sau không?</v-card-text
        >

        <v-card-actions>
          <v-btn color="error" @click="hideSequentialDialog">
            {{ $t("labels.no") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="onCheck">
            {{ $t("labels.yes") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import moment from "moment";
import { generateRandomString, isMobile } from "@/libs/helpers";
import { compress } from "image-conversion";

export default {
  name: "Index",
  components: {
    TimekeepingEmployee: () =>
      import("@/components/administration/TimekeepingEmployee"),
  },
  data: () => ({
    type: "checkin",
    filePath: null,
    fileUpdateTime: null,
    coords: {},
    deviceId: null,
    isTimekeepingAllowed: true,
    isChecking: false,
    dialogList: false,
    isSequential: false,
    sequentialDialog: false,
  }),
  computed: {
    fileUrl() {
      if (!this.filePath) {
        return null;
      }
      return `${process.env.VUE_APP_FILE_CDN_URL}/${this.filePath}`;
    },
  },
  created() {},
  mounted() {
    // this.initializeFingerprint()
    // this.checkActiveDevice()
    this.getLocation();
  },
  methods: {
    isMobile,
    generateRandomString,
    showDialogList() {
      this.dialogList = true;
    },
    hideDialogList() {
      this.dialogList = false;
    },
    handlePermission() {
      const self = this;

      navigator.permissions.query({ name: "geolocation" }).then((result) => {
        if (result.state === "granted") {
          self.getLocation();
        } else if (result.state === "prompt") {
          // Hiển thị một thông báo hoặc nút để yêu cầu quyền truy cập định vị
          self.$t("messages.error_location", { location: result.state });
        } else if (result.state === "denied") {
          self.$vToastify.warning(
            self.$t("messages.can_not_get_location_permission")
          );
        } else {
          self.$vToastify.error(
            self.$t("messages.error_location", { location: result.state })
          );
        }

        /* result.onchange = () => {
          self.handlePermission();
        }; */
      });
    },

    async getLocation() {
      const self = this;
      if (!self.isMobile()) {
        return false;
      }
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            const coords = { latitude, longitude };
            this.coords = { ...coords };
          },
          (err) => {
            switch (err.code) {
              case err.PERMISSION_DENIED:
                self.$vToastify.warning(
                  this.$t("messages.can_not_get_location_permission")
                );
                break;
              case err.POSITION_UNAVAILABLE:
                self.$vToastify.warning(
                  this.$t("messages.can_not_get_location_unavailable")
                );
                break;
              case err.TIMEOUT:
                self.$vToastify.warning(
                  this.$t("messages.can_not_get_location_timeout")
                );
                break;
              case err.UNKNOWN_ERROR:
                self.$vToastify.warning("UNKNOWN_ERROR");
                break;
            }
          },
          {
            enableHighAccuracy: true,
          }
        );
      } else {
        this.$vToastify.warning(this.$t("messages.can_not_get_location_1"));
      }
    },

    async onInputFileChange(e) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;
      let files = e.target.files || e.dataTransfer.files;
      if (files && files[0]) {
        this.filePath = await this.uploadFile(files[0]);
        this.fileUpdateTime = moment(files[0].lastModified).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        this.$refs.inputUploadFile.value = null;
      }
      this.isLoading = false;
    },
    async uploadFile(file) {
      const resBlob = await compress(file, {
        quality: 0.8,
        type: "image/jpeg",
        width: 480,
        height: 640,
      });
      const newFile = new File(
        [resBlob],
        `${moment().unix()}_${generateRandomString(10)}.jpg`,
        { type: "image/jpeg" }
      );
      let fd = new FormData();
      fd.append("file", newFile);

      const { data } = await httpClient.post(`/common/v1/upload-image`, fd);
      return data;
    },

    showSequentialDialog() {
      this.isSequential = true;
      this.sequentialDialog = true;
    },

    hideSequentialDialog() {
      this.isSequential = false;
      this.sequentialDialog = false;
    },

    async onCheck() {
      try {
        const { data } = await httpClient.post(`/me/v1/timekeeping`, {
          code: this.deviceId,
          type: this.type,
          file_url: this.filePath,
          file_time: this.fileUpdateTime,
          lat: this.coords.latitude,
          lng: this.coords.longitude,
          is_sequential: this.isSequential,
          time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        });
        if (
          data &&
          data.type === "checkout" &&
          data.checkinWorkingTimes &&
          data.checkinWorkingTimes.length > 0
        ) {
          //
          this.showSequentialDialog();
        } else {
          this.$vToastify.success(this.$t("messages.timekeep_success"));
          this.filePath = null;
          this.fileUpdateTime = null;
          this.hideSequentialDialog();
        }
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
  },
};
</script>

<style scoped></style>
